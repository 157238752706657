import * as React from 'react';
import Layout from '../components/layout';
import ContactForm from '../components/contactform';

const ContactPage = () => {
  return (
    <Layout pagePath="/contact">
      <div className="container mx-auto px-4 sm:px-0">
        <h1 className="m-0 mb-4 text-4xl"> Contact Me </h1>
        <ContactForm />
      </div> 
    </Layout>
  )
};

export default ContactPage;
